import React from 'react'
import PortableText from './portableText'
import Img from 'gatsby-image'
import { toPlainText, getPreviewImageAltText, getFluidProps } from '../lib/helpers'
import InfoI from '../images/icons/info-i.svg'
import { isMobile } from 'react-device-detect'
import { Fade } from 'react-slideshow-image'
import { sliderSettings } from '../lib/slider'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import ArrowNext from '../images/icons/arrow-next.svg'
import Div100vh from 'react-div-100vh'

class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isInfoTextIn: false,
      isMouseOverOrderLink: false,
      isMouseTooltipVisible: false,
      tooltipArrowDirection: null,
    }
    this.slideRef = React.createRef()
    this.onOrderLinkMouseOver = this.onOrderLinkMouseOver.bind(this)
    this.onOrderLinkMouseLeave = this.onOrderLinkMouseLeave.bind(this)
    this.onInfoToggleClick = this.onInfoToggleClick.bind(this)
    this.onMouseLeaveSlide = this.onMouseLeaveSlide.bind(this)
    this.onMouseEnterSlide = this.onMouseEnterSlide.bind(this)
    this.onMouseOverSlide = this.onMouseOverSlide.bind(this)
  }

  updateHeight = () => {
    const innerH = window.innerHeight
    if (innerH) {
      document.getElementById('product').style.maxHeight = `${innerH}px`
    }
  }

  onInfoToggleClick = () => {
    this.setState({
      isInfoTextIn: !this.state.isInfoTextIn,
    })
  }

  onOrderLinkMouseOver = () => {
    this.setState({
      isMouseOverOrderLink: true,
    })
  }

  onOrderLinkMouseLeave = () => {
    this.setState({
      isMouseOverOrderLink: false,
    })
  }

  onSliderClick = () => {
    const tooltipArrowDirection = this.state.tooltipArrowDirection
    if (tooltipArrowDirection === 'right') {
      this.slideRef.current.goNext()
    } else if (tooltipArrowDirection === 'left') {
      this.slideRef.current.goBack()
    } else {
      this.slideRef.current.goNext()
    }
  }

  updateTooltipArrow(e) {
    let wM = window.innerWidth / 2
    let w = e.clientX
    let pos
    switch (true) {
      case w <= wM:
        pos = 'left'
        break
      case w > wM:
        pos = 'right'
        break
      default:
        pos = undefined
    }
    this.setState({ tooltipArrowDirection: pos })
  }

  onMouseEnterSlide(e) {
    this.updateTooltipArrow(e)
    this.setState({ isMouseTooltipVisible: true })
  }

  onMouseOverSlide(e) {
    this.updateTooltipArrow(e)
  }

  onMouseLeaveSlide() {
    this.setState({ isMouseTooltipVisible: false })
  }

  onSliderClick = () => {
    const tooltipArrowDirection = this.state.tooltipArrowDirection
    if (tooltipArrowDirection === 'right') {
      this.slideRef.current.goNext()
    } else if (tooltipArrowDirection === 'left') {
      this.slideRef.current.goBack()
    } else {
      this.slideRef.current.goNext()
    }
  }

  render() {
    const { title, artist, description, price, _rawProductText, productImageSlider } = this.props
    const slides = productImageSlider && productImageSlider.slides
    const single = slides && slides.length === 1

    return (
      <Div100vh>
        <div className="page-container page-container--product is-nav-hidden">
          <div
            className={`product ${this.state.isInfoTextIn ? 'is-info-text-in' : ''}`}
            id="product"
          >
            <div className="product__mobile-title">
              {title && (
                <>
                  <em>{title}</em>,&nbsp;
                </>
              )}
              {artist && <>{artist}</>}
              <br />
              {description && <>{description}</>}
              <br />
              {price && price} Euro
            </div>
            {slides && (
              <div
                className={`product__image-slider
            ${single ? 'product__image-slider--single-slide' : ''}`}
              >
                <Fade ref={this.slideRef} {...sliderSettings}>
                  {slides.map((slide, i) => {
                    const mainImage = slide && slide.mainImage
                    const fluidProps = mainImage && getFluidProps(mainImage, 2400)
                    const genAltText =
                      title && artist && description
                        ? `Image ${i + 1}/${slides.length}: ${title}, ${artist}, ${description}`
                        : ''
                    return (
                      <div className="each-fade" key={i}>
                        <div
                          className="product__image-slide"
                          onMouseEnter={this.onMouseEnterSlide}
                          onMouseMove={this.onMouseOverSlide}
                          onMouseLeave={this.onMouseLeaveSlide}
                          onClick={!single && this.onSliderClick}
                        >
                          <div className="product__image">
                            {fluidProps && (
                              <Img
                                fluid={fluidProps}
                                imgStyle={{
                                  objectFit: 'contain',
                                  objectPosition: '50% 50%',
                                }}
                                alt={genAltText}
                              />
                            )}
                          </div>
                          {!single && (
                            <MouseTooltip visible={this.state.isMouseTooltipVisible}>
                              <div
                                className={`mouse-tooltip is-${this.state.tooltipArrowDirection}`}
                              >
                                <ArrowNext />
                              </div>
                            </MouseTooltip>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </Fade>
              </div>
            )}
            <div className="product__details" onClick={this.onInfoToggleClick}>
              <div className="product__info-text">
                <div className="product__info-text-inner">
                  <h1>
                    {title && artist && (
                      <>
                        <span>
                          <em>{title}</em>, {artist}
                        </span>
                        <br />
                      </>
                    )}
                    {description && (
                      <>
                        <span>{description}</span>
                        <br />
                      </>
                    )}
                    {price && (
                      <>
                        <span>{price}</span> {isMobile ? '€' : 'Euro'}
                        <br />
                      </>
                    )}
                  </h1>
                  {_rawProductText && <PortableText blocks={_rawProductText} />}
                </div>
              </div>
              <div className="product__buttons">
                <div>
                  <div className="btn product__info-toggle" onClick={this.onInfoToggleClick}>
                    <div className="product__info-toggle-content product__info-toggle-content--desktop">
                      {title && (
                        <>
                          <em>{title}</em>,&nbsp;
                        </>
                      )}
                      {artist && <>{artist},&nbsp;</>}
                      {description && <>{description}</>}
                    </div>
                    <div className="product__info-toggle-content product__info-toggle-content--mobile">
                      <InfoI />
                    </div>
                  </div>
                </div>
                <div
                  className="product__order-link-container"
                  onMouseEnter={this.onOrderLinkMouseOver}
                  onMouseLeave={this.onOrderLinkMouseLeave}
                >
                  <a
                    className="btn product__order-link"
                    href={`mailto:info@balkanprojects.art?subject=Order%20-%20${title}%2C%20${artist}&body=Thank%20you%20for%20your%20interest%20in%20${title}%2C%20${artist}.%20Please%20type%20your%20name%20and%20address%20below%20and%20we%20will%20contact%20you%20shortly%20with%20payment%20and%20shipping%20details.`}
                  >
                    <div>
                      <div className="product__order-link-content product__order-link-content--order-now">
                        Order Now
                      </div>
                      <div className="product__order-link-content product__order-link-content--price">
                        {price} Euro
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Div100vh>
    )
  }
  componentDidMount() {
    this.updateHeight()
  }
  componentDidUpdate() {
    this.updateHeight()
  }
}

export default Product
